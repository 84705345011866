import { http, API_VERSION } from './http'

const SERVICE_SOURCE = `${API_VERSION}/service`

export const totalLoanFacilityAPI = {
  /**
   * Retrieves the total loan facility data based on the totalLoanFacilityId.
   *
   * @param {number} totalLoanFacilityId - The ID of the total loan facility to retrieve.
   * @returns {Promise<Object>} The total loan facility data.
   * @permission service.total-loan-facilities.get
   */
  getTotalLoanFacility(totalLoanFacilityId) {
    return http.get(
      `${SERVICE_SOURCE}/total-loan-facilities/${totalLoanFacilityId}`
    )
  },

  /**
   * Updates a total loan facility based on the provided totalLoanFacilityId.
   *
   * @param {number} totalLoanFacilityId - The ID of the total loan facility to update.
   * @param {Object} params - The params containing the details to update.
   * @param {string} params.totalFacilityNumber - The new total facility number.
   * @param {string} params.totalFacilityName - The new total facility name.
   * @param {string} params.totalFacilityNickname - The new total facility nickname.
   * @param {string} params.status - The new status of the total loan facility.
   * @param {string} params.issuedDate - The new issued date of the total loan facility.
   * @param {number} params.secured - Indicates if the total loan facility is secured.
   * @returns {Promise<Object>} - A promise that resolves with the response of the update operation.
   * @permission service.total-loan-facilities.update
   */
  updateTotalLoanFacility(totalLoanFacilityId, params) {
    return http.put(
      `${API_VERSION}/service/total-loan-facilities/${totalLoanFacilityId}`,
      params
    )
  },

  /**
   * Deletes a Total Loan Facility by its ID.
   *
   * @param {number} totalLoanFacilityId - The ID of the loan facility to be deleted.
   * @returns {Promise<Object>} A promise that resolves with a success message or rejects with an error.
   * @permission service.total-loan-facilities.delete
   */
  deleteTotalLoanFacility(totalLoanFacilityId) {
    return http.delete(
      `${API_VERSION}/service/total-loan-facilities/${totalLoanFacilityId}`
    )
  },
}
