import { http, API_VERSION } from './http'
const USER_API_BASE_PATH = `${API_VERSION}/users`

export const userCenterAPI = {
  /**
   * Get guide view record
   * @permissionCode users.quickstart-single.get
   * @param {string} quickstartCode
   * @returns
   */
  getFunctionModuleGuideRecord(quickstartCode) {
    return http.get(
      `${USER_API_BASE_PATH}/users/quickstart/first-guide/single`,
      {
        quickstartCode: quickstartCode,
      }
    )
  },

  /**
   * Get guides view record
   * @permissionCode users.quickstart-list.get
   * @param {[string]} quickstartCodes
   * @returns
   */
  getFunctionModuleGuideRecords(quickstartCodes) {
    return http.get(`${USER_API_BASE_PATH}/users/quickstart/first-guide/list`, {
      quickstartCodes: quickstartCodes,
    })
  },

  /**
   * Update guide view record
   * @permissionCode users.quickstart.save
   * @param {object} param
   * @param {string} param.quickstartModule
   * @param {string} param.quickstartCode
   * @param {string} param.status FINISHED or SKIPPED
   * @returns
   */
  updateGuideViewRecord({ quickstartModule, quickstartCode, guidanceStatus }) {
    return http.put(`${USER_API_BASE_PATH}/users/quickstart/first-guide`, {
      quickstartModule,
      quickstartCode,
      guidanceStatus,
    })
  },

  /**
   * Get the user configuration for mylist view.
   * @permission config.listView.mylistView
   * @param {object} params - The params object
   * @param {string} params.moduleCode - The module code
   * @param {string} params.listCode - The list code
   * @param {string} params.profileId - The profile ID
   * @param {string} params.cmDynamicListConfigId - The dynamic list config ID
   * @returns {Promise} - A promise that resolves with the user configuration data for mylist view.
   */
  getCustomTableView(params) {
    return http.get(`${API_VERSION}/user/configuration/mylistView`, params)
  },

  /**
   * Get the user custom table view
   * @permission config.listView.mylistView
   * @param {object} params - The params object
   * @param {string} params.moduleCode - The module code
   * @param {string} params.listCode - The list code
   * @param {string} params.profileId - The profile ID
   * @param {string} params.cmDynamicListConfigId - The dynamic list config ID
   * @returns {Promise} - A promise that resolves with the user configuration data for mylist view.
   */
  getCustomTableViews(params) {
    return http.get(`${API_VERSION}/user/configuration/mylistView`, params)
  },

  /**
   * Get the single custom table view
   * @permission config.listView.get
   * @returns {Promise} - A promise that resolves with the user configuration data for mylist view.
   */
  getSingleCustomTableView(listViewId) {
    return http.get(`${API_VERSION}/user/configuration/listView/${listViewId}`)
  },

  /**
   * Add custom table view
   * @permission config.listView.create
   * @param {object} params - The params object
   * @param {string} params.listViewName - Add current list view name
   * @param {boolean} params.isDefaultView - current view is default , default: false
   * @param {string} params.moduleCode - the module code
   * @param {string} params.listCode - the list code
   * @param {string} params.profileId - the profile ID
   * @param {string} params.cmDynamicListConfigId - the dynamic list config ID
   * @param {Array} params.customValue - An array of all filtering options
   * @returns
   */
  createCustomTableView(params) {
    return http.post(`${API_VERSION}/user/configuration/listView`, params)
  },

  /**
   * Update custom table view
   * @permission config.listView.upadte
   * @param {string} listViewId - The list view ID
   * @param {object} params - The params object
   * @param {string} params.listViewName - Add current list view name
   * @param {boolean} params.isDefaultView - current view is default , default: false
   * @param {string} params.moduleCode - the module code
   * @param {string} params.listCode - the list code
   * @param {string} params.profileId - the profile ID
   * @param {string} params.cmDynamicListConfigId - the dynamic list config ID
   * @param {Array} params.customValue - An array of all filtering options
   * @returns
   */
  updateCustomTableView(listViewId, params) {
    return http.put(
      `${API_VERSION}/user/configuration/listView/${listViewId}`,
      params
    )
  },

  /**
   * Delete custom table view option
   * @permission config.listView.delete
   * @param {string} listViewId - The list view ID
   * @param {object} params - The params object
   * @param {string} params.profileId - The use profile ID
   * @param {string} params.cmDynamicListConfigId - The cm dynamic list config ID
   * @returns
   */
  deleteCustomTableView(listViewId, params) {
    return http.delete(
      `${API_VERSION}/user/configuration/listView/${listViewId}`,
      params
    )
  },

  /**
   * Resets the Multi-Factor Authentication (MFA) for a user.
   * @param {string} userId - The ID of the user.
   * @returns {Promise} - A promise that resolves when the MFA reset request is successful.
   */
  resetMFA(userId) {
    return http.post(`${API_VERSION}/users/${userId}/mfa-reset`)
  },

  /**
   * Retrieves the custom field.
   * @returns {Promise<CustomFieldMethodVO[]>} A promise that resolves with an array of custom field.
   */
  getUserDataFieldsConfig() {
    return http.get(`${API_VERSION}/user/configuration/custom-field`)
  },

  /**
   * Retrieves the login method by website code.
   * @param {string} websiteCode - The website code to get the login method for.
   * @returns {Promise<UserLoginMethodVO[]>} A promise that resolves with an array of login methods.
   */
  getLoginMethodByWebsite(websiteCode) {
    return http.get(`${API_VERSION}/users/login-method`, {
      websiteCode,
    })
  },

  /**
   * Verify if an email belongs to a non-loan officer by checking the user's profile.
   * @APICode users.bank-user-verifications.get
   * @param {string} email - The email to be verified.
   * @returns {Promise<VerifyEmailResponse>} - A promise that resolves with the verification result.
   */
  verifyBankUser(email) {
    return http.get(`${API_VERSION}/users/bank-user-verifications`, { email })
  },
}
