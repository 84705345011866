import { http, API_VERSION } from './http.js'
// fixed index rate
const PROMOTIONAL_RATE_PREFIX = `${API_VERSION}/controlCenter`

export const promotionalRateAPI = {
  /**
   * Get promotional rate by ID.
   * @param {string} promotionalRateTableId - The promotional rate ID.
   * @returns {Promise<Object>} - A promise that resolves with the promotional rate data.
   */
  getPromotionalRateById(promotionalRateTableId) {
    return http.get(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${promotionalRateTableId}`
    )
  },

  /**
   * Stash promotional rate.
   * @param {string} promotionalRateTableId - The promotional rate table ID.
   * @param {Object} params - The promotional rate data to stash.
   * @returns {Promise<Object>} - A promise that resolves with the result of the stash operation.
   */
  stashPromotionalRate(promotionalRateTableId, params) {
    return http.post(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${promotionalRateTableId}/stashPromotionalRate`,
      params
    )
  },

  /**
   * Approve promotional rate.
   * @param {string} promotionalRateTableId - The promotional rate table ID.
   * @returns {Promise<Object>} - A promise that resolves with the result of the approval operation.
   */
  approvalPromotionalRate(promotionalRateTableId) {
    return http.patch(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${promotionalRateTableId}/approvalPromotionalRate`
    )
  },

  /**
   * Reject promotional rate.
   * @param {string} promotionalRateTableId - The promotional rate table ID.
   * @returns {Promise<Object>} - A promise that resolves with the result of the rejection operation.
   */
  rejectPromotionalRate(promotionalRateTableId) {
    return http.patch(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${promotionalRateTableId}/rejectPromotionalRate`
    )
  },

  /**
   * Update promotional rate settings.
   * @param {string} promotionalRateTableId - The promotional rate table ID.
   * @param {Object} params - The new promotional rate settings.
   * @returns {Promise<Object>} - A promise that resolves with the result of the update operation.
   */
  updatePromotionalRateSettings(promotionalRateTableId, params) {
    return http.patch(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${promotionalRateTableId}/updatePromotionalRateSettings`,
      params
    )
  },

  /**
   * Get promotional rate alert emails.
   * @param {string} promotionalRateTableId - The promotional rate table ID.
   * @param {Object} params - The parameters for the alert type.
   * @param {string} params.alertType - The type of alert.
   * @returns {Promise<Object>} - A promise that resolves with the promotional rate alert emails.
   */
  getPromotionalRateAlertEmails(promotionalRateTableId, params) {
    return http.get(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${promotionalRateTableId}/getPromotionalRateAlertEmails`,
      params
    )
  },

  /**
   * Promotional rate need update alert email schedule.
   * @returns {Promise<Object>} - A promise that resolves with the result of the schedule operation.
   */
  promotionalRateNeedUpdateAlertEmailSchedule() {
    return http.post(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/promotionalRateNeedUpdateAlertEmailSchedule`
    )
  },

  /**
   * Add promotional rate alert email.
   * @param promotional {stringRate}TableId - The promotional rate table ID.
   * @param {Object} params - The email to add to alert list.
   * @returns {Promise<Object>} - A promise that resolves with the result of the add operation.
   */
  addPromotionalRateAlertEmail(promotionalRateTableId, params) {
    return http.patch(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${promotionalRateTableId}/addPromotionalRateAlertEmail`,
      params
    )
  },

  /**
   * Delete promotional rate alert email by ID.
   * @param {string} promotionalRateTableId - The promotional rate table ID.
   * @param {string} promotionalRateAlertEmailConfigId - The email ID to delete.
   * @returns {Promise<Object>} - A promise that resolves with the result of the delete operation.
   */
  deletePromotionalRateAlertEmailById(
    promotionalRateTableId,
    promotionalRateAlertEmailConfigId
  ) {
    return http.patch(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${promotionalRateTableId}/deletePromotionalRateAlertEmailById/${promotionalRateAlertEmailConfigId}`
    )
  },

  /**
   * Get promotional rate update history.
   * @param {string} fixedRateTableId - The fixed rate table ID.
   * @returns {Promise<Object>} - A promise that resolves with the promotional rate update history.
   */
  getPromotionalRateUpdateHistory(fixedRateTableId) {
    return http.get(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${fixedRateTableId}/getPromotionalRateUpdateHistory`
    )
  },

  /**
   * Export promotional rate update history as CSV.
   * @param {string} fixedRateTableId - The fixed rate table ID.
   * @returns {string} - The URL to download the CSV file.
   */
  exportPromotionalRateUpdateHistoryAsCsv(fixedRateTableId) {
    return `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${fixedRateTableId}/promotionalRateUpdateHistory/export`
  },

  /**
   * Get promotional rate applied product list.
   * @param {string} promotionalRateTableId - The promotional rate table ID.
   * @returns {Promise<Object>} - A promise that resolves with the promotional rate applied product list.
   */
  getPromotionalRateAppliedProductList(promotionalRateTableId) {
    return http.get(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${promotionalRateTableId}/getProductsAppliedPromotionalRateList`
    )
  },

  /**
   * Get email activity list.
   * @param {string} fixedRateTableId - The fixed rate table ID.
   * @returns {Promise<Object>} - A promise that resolves with the email activity list.
   */
  getEmailActivityList(fixedRateTableId) {
    return http.get(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${fixedRateTableId}/emailActivityList`
    )
  },

  /**
   * Get promotional message rate by ID.
   * @param {string} promotionalRateTableId - promotional rate The table ID.
   * @returns {Promise<Object>} - A promise that resolves with the promotional rate message.
   */
  getPromotionalMessageById(promotionalRateTableId) {
    return http.get(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${promotionalRateTableId}/message`
    )
  },

  /**
   * Update promotional rate message.
   * @param {string} promotionalRateTableId - The promotional rate table ID.
   * @param {Object} params - The promotional rate message.
   * @param {string} params.bannerMessageTitle - The promotional rate message banner title.
   * @param {string} params.bannerMessageTitleHyperlink - The promotional rate message banner title hyperlink.
   * @param {string} params.promotionalRateTableId - The promotional rate table ID.
   * @param {string} params.tooltipBeginningMessage - The promotional rate message tooltip beginning message.
   * @param {string} params.tooltipEndingMessage - The promotional rate message tooltip ending message.
   * @returns {Promise<Object>} - A promise that resolves with the result of the update operation.
   */
  updatePromotionalMessage(promotionalRateTableId, params) {
    return http.patch(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${promotionalRateTableId}/message`,
      params
    )
  },

  /**
   * Get promotional rate revision history.
   * @param {string} fixedRateTableId - The fixed rate table ID.
   * @returns {Promise<Object>} - A promise that resolves with the promotional rate revision history.
   */
  getPromotionalRateRevisionHistory(fixedRateTableId) {
    return http.get(
      `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${fixedRateTableId}/getPromotionalRateRevisionHistory`
    )
  },

  /**
   * Export promotional rate revision history as CSV.
   * @param {string} fixedRateTableId - The fixed rate table ID.
   * @returns {string} - The URL to download the CSV file.
   */
  exportPromotionalRateRevisionHistoryAsCsv(fixedRateTableId) {
    return `${PROMOTIONAL_RATE_PREFIX}/promotionalRate/${fixedRateTableId}/promotionalRateRevisionHistory/export`
  },
}
