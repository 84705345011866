const portalBasicPages = [
  // authentications
  {
    path: '',
    name: 'index',
    redirect: '/auth/login',
  },
  {
    path: '/auth/login',
    name: 'login-page',
    meta: {
      darkBackground: true,
      title: 'Login',
    },
    component: () =>
      import(
        /* webpackChunkName: "login-page" */ '@/views/authentication/login/index.vue'
      ),
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password-page',
    meta: {
      darkBackground: true,
      title: 'Forgot Password',
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password-page" */ '@/views/authentication/forgot-password/index.vue'
      ),
  },
  {
    path: '/auth/set-password',
    name: 'set-password-page',
    meta: {
      darkBackground: true,
      title: 'Set Password',
    },
    component: () =>
      import(
        /* webpackChunkName: "set-password-page" */ '@/views/authentication/reset-password/index.vue'
      ),
  },
  {
    path: '/auth/confirm',
    name: 'confirm-page',
    meta: {
      darkBackground: true,
      title: 'Confirm',
    },
    component: () =>
      import(
        /* webpackChunkName: "confirm-page" */ '@/views/authentication/confirm/index.vue'
      ),
  },
  {
    path: '/auth/set-mfa',
    name: 'set-mfa-page',
    meta: {
      darkBackground: true,
      title: 'Set MFA',
    },
    component: () =>
      import(
        /* webpackChunkName: "set-mfa-page" */ '@/views/authentication/set-mfa/index.vue'
      ),
  },
  {
    path: '/auth/security-questions',
    name: 'security-questions-page',
    meta: {
      darkBackground: true,
      title: 'Security Questions',
    },
    component: () =>
      import(
        /* webpackChunkName: "security-questions-page" */ '@/views/authentication/security-questions/index.vue'
      ),
  },
  {
    path: '/auth/sms',
    name: 'sms-page',
    meta: {
      darkBackground: true,
      title: 'SMS',
    },
    component: () =>
      import(
        /* webpackChunkName: "sms-page" */ '@/views/authentication/sms/index.vue'
      ),
  },
  {
    path: '/auth/totp',
    name: 'totp-page',
    meta: {
      darkBackground: true,
      title: 'Totp',
    },
    component: () =>
      import(
        /* webpackChunkName: "totp-page" */ '@/views/authentication/totp/index.vue'
      ),
  },
  {
    path: '/auth/choose-profile',
    name: 'choose-profile-page',
    meta: {
      darkBackground: true,
      title: 'Choose Profile',
    },
    component: () =>
      import(
        /* webpackChunkName: "choose-profile-page" */ '@/views/authentication/choose-profile/index.vue'
      ),
  },
  {
    path: '/auth/sso-register',
    name: 'sso-register-page',
    meta: {
      darkBackground: true,
      title: 'SSO Register',
    },
    component: () =>
      import(
        /* webpackChunkName: "login-page" */ '@/views/authentication/sso-register/index.vue'
      ),
  },
  {
    path: '/auth/sso-login',
    name: 'sso-login-page',
    meta: {
      darkBackground: true,
      title: 'SSO Login',
    },
    component: () =>
      import(
        /* webpackChunkName: "login-page" */ '@/views/authentication/sso-login/index.vue'
      ),
  },
  {
    path: '/auth/check-access-code',
    name: 'check-access-code-page',
    meta: {
      darkBackground: true,
      title: 'Check Access Code',
    },
    component: () =>
      import(
        /* webpackChunkName: "login-page" */ '@/views/authentication/check-access-code/index.vue'
      ),
  },
  {
    path: '/auth/access-confirm',
    name: 'authorize-scope-page',
    meta: {
      darkBackground: true,
      title: 'Check Access Code',
    },
    component: () =>
      import(
        /* webpackChunkName: "login-page" */ '@/views/authentication/authorize/scope.vue'
      ),
  },
  {
    path: '/auth/invalid-link',
    name: 'invalid-link',
    meta: {
      darkBackground: true,
      title: 'INVALID LINK',
    },
    component: () =>
      import(
        /* webpackChunkName: "login-page" */ '../views/authentication/invalid-link/index'
      ),
  },
]

export default portalBasicPages
