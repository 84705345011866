export const ModuleRouterEnum = {
  service: '/service',
  client: '/smp',
  application: '/application',
  proposal: '/nav/proposal',
  underwriting: '/underwriting',
  estimate: '/nav/estimate',
  cm: '/collateral-management',
  origination: '/application/origination',
  proposals: '/nav/proposals',
}

export const ModulePathsEnum = {
  origination: `${ModuleRouterEnum.origination}, ${ModuleRouterEnum.proposals}`,
  service: `${ModuleRouterEnum.service}, ${ModuleRouterEnum.client}`,
  cm: `${ModuleRouterEnum.cm}`,
}

export const GroupTypeEnum = {
  line: 'LOAN_FACILITY',
  collateralCall: 'RISK_EVENT',
  drawRequest: 'DRAW_REQUEST',
  collateralRelease: 'COLLATERAL_RELEASE',
  collateral: 'COLLATERAL',
  amendment: 'AMENDMENT',
  application: 'APPLICATION',
  proposal: 'PROPOSAL',
  estimate: 'ESTIMATE',
  underwriting: 'UNDERWRITING',
  asset: 'ASSET',
  loan: 'LOAN',
}

export const SearchResponseEnum = {
  LOAN_FACILITY: 'loanFacilities',
  RISK_EVENT: 'collateralCalls',
  DRAW_REQUEST: 'drawRequests',
  COLLATERAL_RELEASE: 'collateralReleaseRequests',
  COLLATERAL: 'collaterals',
  AMENDMENT: 'amendment',
  APPLICATION: 'applications',
  PROPOSAL: 'proposals',
  ESTIMATE: 'estimates',
  LOAN: 'loans',
  UNDERWRITING: 'underwriting',
  ASSET: 'assets',
}

export const ResultToggleMoreLength = {
  lineAssets: 3,
  linePerson: 3,
  originationAssets: 3,
  originationPerson: 3,
}

export const RiskModeEnum = {
  call: 'CALL',
  shortfall: 'SHORTFALL',
}

export const RoleTypeEnum = {
  lender: 'LENDER',
  servicer: 'SERVICER',
  funder: 'FUNDER_USER',
  support: 'SUPPORT',
  channel: 'CHANNEL',
  investment: 'INVESTMENT',
  customer: 'CUSTOMER',
}

export const AuthEnum = {
  open: 3,
  read: 2,
  close: 1,
}

export const noDataTypeEnum = {
  noData: '1',
  clickStar: '2',
  noResult: '3',
  needSearch: '4',
  addAccount: '5',
}
