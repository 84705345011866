import { ModulePathsEnum, AuthEnum } from './global-search.enum.js'

export default {
  computed: {
    showGlobalSearch() {
      return (
        (this.modulePathsIncludeCurrentPage(ModulePathsEnum.service) &&
          this.hasAuthed(
            'globalSearchAccess',
            AuthEnum.open,
            'SERVICE_GLOBAL_SEARCH'
          )) ||
        (this.modulePathsIncludeCurrentPage(ModulePathsEnum.origination) &&
          this.hasAuthed(
            'globalSearchAccess',
            AuthEnum.open,
            'ORIGINATION_GLOBAL_SEARCH'
          )) ||
        (this.modulePathsIncludeCurrentPage(ModulePathsEnum.cm) &&
          this.hasAuthed('showGlobalSearch', AuthEnum.open, 'CM_GLOBAL_SEARCH'))
      )
    },
  },
  methods: {
    modulePathsIncludeCurrentPage(modulePaths) {
      return modulePaths
        .split(', ')
        .some((modulePath) =>
          this.$lodash.startsWith(this.$route.path, modulePath)
        )
    },
  },
}
