import '@sn/framework/src/plugins/vue'
import createEntry from '@sn/framework'
import scopeRoutes from './router/index'
import scopeStore from './store/index'
import scopeI18n from '../static/lang/en.json'
import cssVars from 'css-vars-ponyfill'

const baseUrl = process.env.VUE_APP_BASE_PATH
const portalName = process.env.VUE_APP_PRODUCT_NAME
const entry = createEntry()
cssVars()
entry
  .registerRoutes(portalName, baseUrl, scopeRoutes)
  .registerStore(portalName, scopeStore)
  .registerI18n(baseUrl, scopeI18n)
  .create()

const { register } = entry || {}
const { i18n } = register || {}
const { messages, locale } = i18n || {}
const cdnMessages = messages[locale] || {}
// eslint-disable-next-line no-unused-expressions
i18n?.mergeLocaleMessage(locale, scopeI18n)
// eslint-disable-next-line no-unused-expressions
register?.addI18nFormCDN(cdnMessages)
