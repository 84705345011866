// @ts-ignore
import { http, API_VERSION } from '@/shared/api/http'

/**
 * @typedef {Object} Advisor
 * @property {string} advisorName - The name of the advisor.
 * @property {string[]} roles - The roles of the advisor.
 * @property {string} email - The email of the advisor.
 */

/**
 * @typedef {Object} Party
 * @property {number} partyId - The ID of the partyproperty {string}.
 * @partyName - Theproperty name.
 {string[]} roles - of the party * @ The roles of the party.
 * @property {string} email - The email of the party.
 */

/**
 * @typedef {Object} Estimate
 * @property {number} estimateId - The ID of the estimate.
 * @property {string} name - The name of the estimate.
 * @property {string} status - The status of the estimate.
 * @property {string[]} accountNumbers - The account numbers associated with the estimate.
 * @property {Advisor[]} advisors - The advisors associated with the estimate.
 */

/**
 * @typedef {Object} Proposal
 * @property {number} proposalId - The ID of the proposal.
 * @property {string} name - The name of the proposal.
 * @property {string} status - The status of the proposal.
 * @[]} accountNumbers -property {string The account numbers associated with the proposal.
 * @property {Party[]} parties - The parties associated with the proposal.
 * @property {Advisor[]} advisors - The advisors associated with the proposal.
 */

/**
 * @typedef {Object} Application
 * @property {number} applicationId - The ID of the application.
 * @property {string} name - The name of the application.
 * @property {string} applicationNumber - The application number.
 * @property {string} status - The status of the application.
 * @property {string[]} accountNumbers - The account numbers associated with the application.
 * @property {Party[]} parties - The parties associated with the application.
 * @property {Advisor[]} advisors - The advisors associated with the application.
 * @property {string} applicationStage - The application stage.
 */

/**
 * @typedef {Object} LoanFacility
 * @property {number} loanFacilityId - The ID facility of the loan.
 * @property {string} loanFacilityNumber - The loan facility number.
 * @property {string} loanFacilityName - The loan facility name of the.
 * @property {string} nickname - The nickname of the loan facility.
 * @property {string[]} assetNumbers - The asset numbers associated with the loan facility.
 * @property {Party[]} parties - The parties associated with the loan facility.
 * @property {Advisor[]} advisors - The advisors associated with the loan facility.
 */

/**
 * @typedef {Object} GlobalSearchResponse
 * @property {Estimate[]} estimates - A list of estimates.
 * @property {[]} proposalsProposal - A list of proposals.
 * @property {Application[]} applications - A list of applications.
 * @property {LoanFacility[]} loanFacilities - A list of loan facilities.
 * @property {Object[]} loans - A list of loans.
 * @property {Object[]} collaterals - A list ofater collals.
 * @property {Object[]} drawRequests - A list of draw requests.
 * @property {Object[]} collateralCalls - A list of collateral calls.
 * @property {Object[]} collateralReleaseRequests - A list of collateral release requests.
 * @property {Object[]} assets - A list of assets.
 */

export const globalSearchAPI = {
  /**
   * Performs a global search based on the given search term and business object types.
   * @param {Object} params - The search parameters.
   * @param {string} params.searchTerm - The term to search for.
   * @param {string[]} params.businessObjectTypes - The types of business objects to search for.
   * @param {string} [params.loanFacilityStatus] - The status of the loan facility (optional).
   * @returns {Promise<GlobalSearchResponse>} - A promise that resolves with the search results.
   */
  getGlobalSearch(params) {
    return http.get(`${API_VERSION}/global-searches?${params}`)
  },
}
