<template>
  <div class="search-group-box">
    <s-icon
      class="search-icon"
      icon-name="search"
      :aria-label="$t('search')"
      :height="iconSize"
      :width="iconSize"
      @click.native="searchHandler"
    />
    <s-input
      class="search-input"
      ref="searchInput"
      :value="value"
      :maxlength="maxlength"
      :aria-label="$t('search_by_keyword')"
      :placeholder="$t('search_by_keyword')"
      @input.native="handleInput"
      @focus.native="searchFocused"
    />
    <s-icon
      v-show="value"
      class="clear-icon"
      icon-name="old-cancel"
      :height="iconSize"
      :width="iconSize"
      @click.native="clearSearch"
    />
  </div>
</template>

<script>
export default {
  props: {
    iconSize: {
      type: Number,
      default: 24,
    },
    maxlength: {
      type: Number,
      default: 30,
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {}
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value)
    },
    searchFocused() {
      this.$emit('focus')
    },
    searchHandler() {
      this.$emit('search')
    },
    clearSearch() {
      this.$emit('input', '')
    },
  },
}
</script>

<style lang="scss" scoped>
.search-group-box {
  flex: 1;
  position: relative;
  :deep(.input-group-append) {
    background: transparent;
    border: none;
  }
  .search-icon,
  .clear-icon {
    color: rgba(0, 0, 0, 0.5);
    background: #ffffff;
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 8px;
  }
  .search-icon {
    left: 8px;
  }
  .clear-icon {
    right: 8px;
  }
  .search-input {
    background: $white;
    padding: 6px 36px;
  }
}
</style>
