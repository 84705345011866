<template>
  <a
    v-if="showGlobalSearch"
    class="nav-link pr-4 pl-4"
    href="javascript:void(0)"
    tabindex="0"
    :aria-label="$t('global_search')"
    @click="handleShowSearchModal"
    @keyup.enter="handleShowSearchModal"
  >
    <s-icon icon-name="search" :width="iconSize" :height="iconSize"></s-icon>
  </a>
</template>

<script>
import globalSearchMixin from './global-search.mixin'

export default {
  name: 'global-search',
  mixins: [globalSearchMixin],
  data() {
    return {
      iconSize: 24,
    }
  },
  methods: {
    handleShowSearchModal() {
      this.$store.commit('setShowGlobalSearch', true)
    },
  },
}
</script>
