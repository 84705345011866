/** @enum */
export const PermissionValueEnum = {
  block: 1,
  readOnly: 2,
  readAndWrite: 3,
}

export const CallResolutionActionCodes = {
  payBalance: 'PAY_BALANCE',
  purchaseHolding: 'PURCHASE_HOLDING',
  sellHolding: 'SELL_HOLDING',
  addAccount: 'ADD_ACCOUNT',
  addCash: 'ADD_CASH',
}

export const AssetClassEnum = {
  cash: 'CASH',
}

export const CallResolutionEventEnum = {
  simulatedChange: 'SIMULATED_CHANGE',
  resolveAction: 'RESOLVE_ACTION',
}

export const RiskLevelTypeEnum = {
  blue: 'BLUE',
  green: 'GREEN',
  yellow: 'YELLOW',
  red: 'RED',
  purple: 'PURPLE',
}

export const APIStageEnum = {
  estimate: 'estimate',
  proposal: 'proposal',
  origination: 'origination',
  application: 'application',
  underwriting: 'underwriting',
  service: 'service',
  amendment: 'amendment',
}

export const SessionKeyEnum = {
  totalLoanFacility: 'TOTAL_LOAN_FACILITY',
}

/**
 * @readonly
 * @enum {string}
 */
export const RoleTypeEnum = {
  lender: 'LENDER',
  servicer: 'SERVICER',
  funder: 'FUNDER_USER',
  support: 'SUPPORT',
  channel: 'CHANNEL',
  investment: 'INVESTMENT',
  customer: 'CUSTOMER',
}

/**
 * ! conditional and frozen property is frontend custom status for line table tab, not save in db
 * @readonly
 * @enum {string}
 */
export const LineStatusEnum = {
  active: 'ACTIVE',
  pending: 'PENDING',
  pendingClose: 'PENDING_CLOSE',
  closed: 'CLOSED',
  conditional: 'CONDITIONAL',
  frozen: 'FROZEN',
}
