<template>
  <div v-if="visible" class="banner-notifications">
    <template v-for="(notification, index) of notifications">
      <s-alert
        v-if="notification.visible"
        :key="notification.bannerNotificationSettingDisplayId"
        :id="$generateId('banner-notification-' + index)"
        class="mb-24"
        closeAble
        @close="handleCloseNotification(notification)"
      >
        <h4 v-if="notification.bannerTitle">{{ notification.bannerTitle }}</h4>
        <banner-content
          v-if="notification.content"
          :content="notification.content"
        />
      </s-alert>
    </template>
  </div>
</template>
<script>
import BannerContent from './BannerContent.vue'
export default {
  components: { BannerContent },
  computed: {
    visible() {
      return (
        this.notifications.length &&
        this.notifications.some((notification) => notification.visible)
      )
    },
    notifications() {
      let notifications = this.$store.state.userInfo?.bannerNotificationList
      if (Array.isArray(notifications)) {
        const customBlackListPath = '/nav/estimate/mine'
        notifications = notifications
          .filter((notification) => notification.isActive)
          .map((notification) => ({
            ...notification,
            visible: (notification.isCustomizedDisplayPageNode
              ? this.getCustomVisiblePath(notification)
              : this.defaultVisiblePath
            ).some(
              (path) =>
                this.$route.fullPath.match(path) &&
                !this.$route.fullPath.match(customBlackListPath)
            ),
          }))
      }
      return notifications
    },
    defaultVisiblePath() {
      const paths = []
      const userInfo = this.$store.state.userInfo

      if (userInfo.profile.roleType === 'CUSTOMER') {
        // application id length is 18 normally
        paths.push('/service/my-line', /\/application\/\d{1,24}/i)
      }

      const menuList = userInfo?.roleConfiguration?.menuList || []
      let topMenus = menuList.filter((item) => {
        return item.type === 'topMenu' && item.path
      })
      topMenus = this.$lodash.orderBy(topMenus, ['displayOrder'], ['asc'])
      const defaultPath = userInfo?.roleConfiguration?.defaultPagePath || ''
      const isTopMenusIncludesDefaultPath = topMenus.some((item) => {
        return item.path === defaultPath
      })
      if (isTopMenusIncludesDefaultPath) {
        paths.push(defaultPath)
      } else {
        paths.push(topMenus[0].path)
      }
      return paths
    },
  },
  methods: {
    getCustomVisiblePath(notification) {
      const paths = (notification.locationPageCodeList || '').split(',').map(
        (pathCode) =>
          ({
            MY_ESTIMATE: '/nav/estimate',
            ESTIMATE: '/nav/estimate',
            MY_APPLICATION: /\/application\/\d{1,24}/i,
            APPLICATION: /\/application\/\d{1,24}/i,
          }[pathCode])
      )
      return paths
    },
    handleCloseNotification({ bannerNotificationSettingDisplayId }) {
      this.removeNotification(bannerNotificationSettingDisplayId)
      this.removeNotificationInStore(bannerNotificationSettingDisplayId)
    },
    removeNotification(notificationId) {
      if (Array.isArray(this.notifications)) {
        const notificationIndex = this.notifications.findIndex(
          (notification) =>
            notification.bannerNotificationSettingDisplayId === notificationId
        )
        if (notificationIndex !== -1) {
          this.notifications.splice(notificationIndex, 1)
        }
      }
    },
    removeNotificationInStore(notificationId) {
      const userInfo = this.$store.state.userInfo
      const notifications = userInfo?.bannerNotificationList
      if (Array.isArray(notifications)) {
        const notificationIndex = notifications.findIndex(
          (notification) =>
            notification.bannerNotificationSettingDisplayId === notificationId
        )
        if (notificationIndex !== -1) {
          notifications.splice(notificationIndex, 1)
          this.$store.commit('setUserInfo', userInfo)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
:deep(.banner-notification__content) {
  :first-child {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  :last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
