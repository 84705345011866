import { http, API_VERSION } from './http.js'
// fixed index rate
const FIXED_RATE_PREFIX = `${API_VERSION}/controlCenter`

export const fixedRateAPI = {
  /**
   * Get fixed rate list by institutionId.
   * @returns {Promise<Array>} A promise that resolves with the fixed rate list.
   * Each item in the array contains fixed rate information.
   */
  getFixedRates() {
    return http.get(`${FIXED_RATE_PREFIX}/fixedRate`)
  },

  /**
   * Get fixed rate details by fixedRateId.
   * @param {string} fixedRateId - The ID of the fixed rate.
   * @returns {Promise<Object>} A promise that resolves with the fixed rate details.
   */
  getFixedRateById(fixedRateId) {
    return http.get(`${FIXED_RATE_PREFIX}/fixedRate/${fixedRateId}`)
  },

  /**
   * Approval fixed rate.
   * @param {string} fixedRateTableId - The ID of the fixed rate table.
   * @returns {Promise<Object>} A promise that resolves with the approval response.
   */
  approvalFixedRate(fixedRateTableId) {
    return http.patch(
      `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/approvalFixedRate`
    )
  },

  /**
   * Get fixed rate setting notification email list.
   * @param {string} fixedRateTableId - The ID of the fixed rate table.
   * @param {Object} params - The parameters to filter the email list.
   * @param {string} params.alertType - The type of alert for the email.
   * @returns {Promise<Array>} A promise that resolves with the email list.
   */
  getFixedRateAlertEmails(fixedRateTableId, params) {
    return http.get(
      `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/getFixedRateAlertEmails`,
      params
    )
  },

  /**
   * Get fixed rate email activity list by fixed rate identifiers.
   * @param {string} fixedRateTableId - The ID of the fixed rate table.
   * @returns {Promise<Array>} A promise that resolves with the email activity list.
   */
  getEmailActivityList(fixedRateTableId) {
    return http.get(
      `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/emailActivityList`
    )
  },

  /**
   * Add fixed rate setting notification email configuration.
   * @param {string} fixedRateTableId - The ID of the fixed rate table.
   * @param {Object} params - The email configuration parameters.
   * @param {string} params.alertEmailConfigId - The ID of the alert email configuration.
   * @param {string} params.alertTypeEnum - The type of alert.
   * @param {string} params.createdAt - The creation date of the configuration.
   * @param {string} params.email - The email address to be added.
   * @returns {Promise<Object>} A promise that resolves with the addition response.
   */
  addFixedRateAlertEmail(fixedRateTableId, params) {
    return http.patch(
      `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/addFixedRateAlertEmail`,
      params
    )
  },

  /**
   * Delete notification email configuration by alertEmailConfigId.
   * @param {string} fixedRateTableId - The ID of the fixed rate table.
   * @param {string} fixedRateAlertEmailConfigId - The ID of the fixed rate alert email configuration.
   * @returns {Promise<Object>} A promise that resolves with the deletion response.
   */
  deleteFixedRateAlertEmailById(fixedRateTableId, fixedRateAlertEmailConfigId) {
    return http.patch(
      `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/deleteFixedRateAlertEmailById/${fixedRateAlertEmailConfigId}`
    )
  },

  /**
   * Get fixed rate event summary update history.
   * @param {string} fixedRateTableId - The ID of the fixed rate table.
   * @returns {Promise<Array>} A promise that resolves with the update history.
   */
  getFixedRateUpdateHistory(fixedRateTableId) {
    return http.get(
      `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/getFixedRateUpdateHistory`
    )
  },

  /**
   * Get product applied list of one fixed rate.
   * @param {string} fixedRateTableId - The ID of the fixed rate table.
   * @returns {Promise<Array>} A promise that resolves with the products list.
   */
  getFixedRateAppliedProductList(fixedRateTableId) {
    return http.get(
      `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/getProductsAppliedFixedRateList`
    )
  },

  /**
   * Import fixed rate from template file.
   * @param {string} fixedRateTableId - The fixed rate table ID.
   * @param {Object} params - The file to be uploaded and additional request information.
   * @param {File} params.file - The template file to import.
   * @param {string} params.fixedRateTableRequest - Additional request information.
   * @returns {Promise<Object>} A promise that resolves with the import response.
   */
  stashFixedRate(fixedRateTableId, params) {
    return http.post(
      `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/stashFixedRate`,
      params
    )
  },

  /**
   * Reject fixed rate.
   * @param {string} fixedRateTableId - The ID of the fixed rate table.
   * @returns {Promise<Object>} A promise that resolves with the rejection response.
   */
  rejectFixedRate(fixedRateTableId) {
    return http.patch(
      `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/rejectFixedRate`
    )
  },

  /**
   * Update fixed rate setting.
   * @param {string} fixedRateTableId - The ID of the fixed rate table.
   * @param {Object} params - The fixed rate settings parameters.
   * @param {boolean} params.isRateChangeExceedThresholdNeedEmail - Whether email is needed when rate change exceeds threshold.
   * @param {boolean} params.rateChangeNeedEmail - Whether email is needed on rate change.
   * @param {number} params.rateChangeThresholdPercentValue - The percentage value of rate change threshold.
   * @param {boolean} params.rateRequiresApprovalNeedEmail - Whether email is needed when rate requires approval.
   * @param {string} params.rateUpdatesReminderFrequency - The frequency of rate updates reminder.
   * @param {boolean} params.reminderScheduled - Whether the reminder is scheduled.
   * @returns {Promise<Object>} A promise that resolves with the update response.
   */
  updateFixedRateSettings(fixedRateTableId, params) {
    return http.patch(
      `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/updateFixedRateSettings`,
      params
    )
  },

  // Helper functions to get URLs for file operations, not actual API calls.

  /**
   * Get URL for stashing fixed rate.
   * @param {string} fixedRateTableId - The ID of the fixed rate table.
   * @returns {string} The URL for stashing fixed rate.
   */
  getStashFixedRateUrl(fixedRateTableId) {
    return `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/stashFixedRate`
  },

  /**
   * Get URL for exporting fixed rate update history.
   * @param {string} fixedRateTableId - The ID of the fixed rate table.
   * @returns {string} The URL for exporting fixed rate update history.
   */
  exportFixedRateUpdateHistory(fixedRateTableId) {
    return `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/fixedRateUpdateHistory/export`
  },

  /**
   * Get URL for uploading fixed rate file.
   * @returns {string} The URL for uploading fixed rate file.
   */
  getUploadFixedRateFileUrl() {
    return `${FIXED_RATE_PREFIX}/fixedRate/uploadFixedRateFile`
  },

  getDownloadPrepareToken(fileStorageId) {
    return http.request({
      method: 'get',
      url: `${FIXED_RATE_PREFIX}/file/${fileStorageId}/downloadPrepare`,
      responseType: 'text/plain',
    })
  },

  /**
   * Get URL for downloading history file.
   * @param {string} fixedRateTableId - The ID of the fixed rate table.
   * @param {string} eventId - The event ID for the history file.
   * @returns {string} The URL for downloading the history file.
   */
  getDownloadHistoryFileUrl(fixedRateTableId, eventId) {
    const paramStr = eventId ? `?eventId=${eventId}` : ''
    return `${FIXED_RATE_PREFIX}/fixedRate/${fixedRateTableId}/downloadHistoryFile${paramStr}`
  },

  /**
   * Get URL for fixed rate need update alert email schedule.
   * @returns {string} The URL for fixed rate need update alert email schedule.
   */
  fixedRateNeedUpdateAlertEmailSchedule() {
    return `${FIXED_RATE_PREFIX}/fixedRate/fixedRateNeedUpdateAlertEmailSchedule`
  },
}
