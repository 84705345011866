import { http, API_VERSION } from './http'

export const configurationAPI = {
  /**
   * List Website Level Configurations
   * This API is designed to list website level configurations based on the provided website code and category code.
   * It fetches both general website-level configurations and specific category configurations, merging them as necessary for the response.
   *
   * @param {string} stage - The stage of the process (e.g., proposal, application, underwriting, service).
   * @param {Object} params - The request parameters.
   * @param {string} [params.categoryCode] - The category code to fetch specific configurations for.
   * @returns {Promise<Object>} The website level configurations including general and specific category configurations.
   * @apiCode {stage}.configurations.website-levels.list
   */
  listWebsiteLevelConfigs(stage, params) {
    return http.get(
      `${API_VERSION}/${stage}/configurations/website-levels`,
      params
    )
  },

  /**
   * Lists configuration enums based on given institution ID and enum type codes.
   *
   * @param {Object} params - The request parameters.
   * @param {number} [params.institutionId] - The ID of the institution (optional).
   * @param {Array<string>} params.enumTypeCodes - The list of enum type codes (required).
   * @returns {Promise<Object>} A promise resolving to the configuration enums.
   * @apiCode configurations.enums.list
   */
  listConfigurationEnums(params) {
    return http.get(`${API_VERSION}/configurations/enums`, params)
  },

  /**
   * List Institution Level Configurations based on the given parameters.
   * It allows fetching configurations for lender and channel institutions, including general and specific category configurations, data points, dashboard data points, and customized field configurations.
   *
   * @param {string} stage - The stage of the institution config category.
   * @param {Object} params - The request parameters.
   * @param {string} [params.categoryCode] - For Estimate/Proposal only need query general institution config.
   * @param {number} [params.loanFacilityId] - The loan facility ID.
   * @param {number} [params.applicationId] - The application ID.
   * @param {number} [params.amendmentId] - The amendment ID.
   * @param {number} [params.estimateId] - The estimate ID.
   * @returns {Promise<Object>} The institution level configurations.
   * @apiCode service.configurations.listInstitutionLevels
   */
  listInstitutionLevelConfigs(stage, params) {
    return http.get(
      `${API_VERSION}/v5/${stage}/configurations/institution-levels`,
      params
    )
  },
  /**
   * List configurations for LOC Product Level.
   *
   * @param {string} stage - The stage of the LOC product.
   * @param {Object} params - The request parameters.
   * @param {number} params.locProductId - The ID of the LOC product.
   * @param {string} [params.categoryCode] - The category code related to the stage.
   * @returns {Promise<Object>} The LOC Product Level configurations.
   * @apiCode service.configurations.locProductLevels.list
   */
  listLocProductLevelConfigurations(stage, params) {
    return http.get(
      `${API_VERSION}/${stage}/configurations/loc-product-levels`,
      params
    )
  },
}
