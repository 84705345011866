<template>
  <div>
    <h4 class="error-title">{{ message }}</h4>
    <div class="error-info mt-4" v-if="requestId || code">
      <strong>{{ $ts('request_id_colon') }}</strong>
      {{ requestId }}
      <br />
      <strong>{{ $ts('code_colon') }}</strong>
      {{ code }}
    </div>
    <s-button
      class="mt-4"
      v-if="requestId || code"
      size="sm"
      @click.native="copy"
      button-type="outline-primary"
      >{{ $ts('copy') }}</s-button
    >
  </div>
</template>

<script>
export default {
  name: 'SystemError',
  props: {
    message: {
      type: String,
      default: '',
    },
    requestId: {
      type: String,
      default: '',
    },
    code: {
      type: String,
      default: '',
    },
  },
  methods: {
    copy() {
      this.$copyText(
        `${this.$ts('request_id_colon')} ${this.requestId} ${this.$ts(
          'code_colon'
        )}: ${this.code}`
      ).then(() => {
        this.$s_toast({
          type: 'success',
          message: this.$ts('copy_success'),
          placement: 'top',
          closeTime: 5000,
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.error-title {
  margin: 0;
}
.error-info {
  font-size: 14px;
  color: #4d627b;
  background: #eee;
  border-radius: 3px;
  padding: 4px 8px;
  margin-bottom: 4px;
  margin-top: 8px;
}
</style>
