const sessionStorage = window.sessionStorage

/**
 * Get session storage by keyName
 * @param {String} keName A string containing the name of the key you want to get.
 * @param {Any} [defaultValue] Set a default value when value is null
 * @return {Any}
 */
export function getSessionStorageItem(keyName, defaultValue = null) {
  const result = sessionStorage.getItem(keyName)
  if (result === null) {
    return defaultValue
  }

  try {
    return JSON.parse(result)
  } catch (e) {
    return defaultValue
  }
}

/**
 * Set session storage by keyName
 * @param {String} keName A string containing the name of the key you want to set.
 * @return {Undefined} None
 */
export function setSessionStorageItem(keyName, keyValue) {
  sessionStorage.setItem(keyName, JSON.stringify(keyValue))
}

/**
 * Remove session storage by keyName
 * @param {String} keName A string containing the name of the key you want to remove.
 * @return {Undefined} None
 */
export function removeSessionStorageItem(keyName) {
  sessionStorage.removeItem(keyName)
}

/**
 * Remove session storage by keyName array
 * @param {String[]} keNames A string array containing the name of the key you want to remove.
 * @return {Undefined} None
 */
export function removeSessionStorageItems(keyNames) {
  keyNames.forEach(keyName => {
    sessionStorage.removeItem(keyName)
  })
}

/**
 * Clear session storage
 * @param {String[]} exceptKeyNames A string array containing the name of the key you want to reserve.
 * @returns {Undefined} None
 */
export function clearSessionStorage(exceptKeyNames) {
  if (!exceptKeyNames) {
    sessionStorage.clear()
    return
  }
  for (let i = 0; i < sessionStorage.length; i++) {
    const keyName = sessionStorage.key(i)
    if (!exceptKeyNames.includes(keyName)) {
      sessionStorage.removeItem(keyName)
    }
  }
}
