import _ from 'lodash'
import * as transfer from './transfer'
import * as is from './is'

import { TIME_ZONE, TIME_ZONE_APPEND, getSystemLocale } from './time-zone'

/**
 * Except String, Number, any other type is invalid.
 * If options.emptyStringToInvalid is true, the empty string to invalid string.
 *
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.invalid
 * @param {Boolean} options.emptyStringToInvalid
 * @param {Array} options.include If mix in the include array, the mix is invalid.
 * @returns {String}
 */
export function text(
  mix,
  { invalid = '-', emptyStringToInvalid = true, include = [] } = {}
) {
  if (_.isNumber(mix) && _.isFinite(mix)) {
    mix = String(mix)
  }

  const hasMix = _.isArray(include) && include.includes(mix)
  const isEmptyMix = emptyStringToInvalid && is.isEmptyString(mix)
  const isNotStringMix = !_.isString(mix)
  if (hasMix || isEmptyMix || isNotStringMix) {
    mix = invalid
  }
  return mix
}

/**
 * @param {*} mix
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append e.g. CT => 07/01/2020 CT
 * @param {String} options.timezone
 * @param {Boolean} options.zeroInvalid
 */
export function datetime(
  mix,
  {
    format = 'MM/DD/YYYY HH:mm',
    invalid = '-',
    timezone = null,
    append = '',
    zeroInvalid = true,
  } = {}
) {
  if (zeroInvalid && /^0$/.test(mix)) {
    return invalid
  }

  const m = transfer.toMoment(mix, { timezone })
  if (!m.isValid()) {
    return invalid
  }

  if (append) {
    format += ' \\' + append.split('').join('\\')
  }

  return m.format(format)
}

/**
 * @param {*} mix
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {String} options.timezone
 * @param {Boolean} options.zeroInvalid
 */
export function date(
  mix,
  {
    format = 'L',
    invalid = '-',
    timezone = null,
    append = '',
    zeroInvalid = true,
  } = {}
) {
  return datetime(mix, { format, invalid, append, timezone, zeroInvalid })
}

/**
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {String} options.timezone
 * @param {Boolean} options.zeroInvalid
 */
export function time(
  mix,
  {
    format = 'LT',
    invalid = '-',
    timezone = '',
    append = '',
    zeroInvalid = true,
  } = {}
) {
  return datetime(mix, { format, invalid, append, timezone, zeroInvalid })
}

/**
 * @deprecated Please use 'datetimeSystemZone' instead
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {Boolean} options.zeroInvalid
 */
export function datetimeChicago(
  mix,
  {
    format = 'MM/DD/YYYY HH:mm',
    invalid = '-',
    append = TIME_ZONE_APPEND,
    zeroInvalid = true,
  } = {}
) {
  return datetimeSystemZone(mix, { format, invalid, append, zeroInvalid })
}

/**
 * @deprecated Please use 'dateSystemZone' instead
 * @param {*} mix
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {Boolean} options.zeroInvalid
 */
export function dateChicago(
  mix,
  {
    format = 'L',
    invalid = '-',
    append = TIME_ZONE_APPEND,
    zeroInvalid = true,
  } = {}
) {
  return datetimeSystemZone(mix, { format, invalid, append, zeroInvalid })
}

/**
 * @deprecated Please use 'timeSystemZone' instead
 * @param {*} mix
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {Boolean} options.zeroInvalid
 */
export function timeChicago(
  mix,
  {
    format = 'LT',
    invalid = '-',
    append = TIME_ZONE_APPEND,
    zeroInvalid = true,
  }
) {
  return datetimeSystemZone(mix, { format, invalid, append, zeroInvalid })
}

/**
 * @param {*} mix
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {Boolean} options.zeroInvalid
 * @description Please change timezone by such as $utils.setSessionStorage(TIME_ZONE_KEY, 'America/Los_Angeles')
 */
export function datetimeSystemZone(
  mix,
  {
    format = 'MM/DD/YYYY HH:mm',
    invalid = '-',
    append = '',
    zeroInvalid = true,
  } = {}
) {
  return datetime(mix, {
    format,
    invalid,
    append,
    zeroInvalid,
    timezone: TIME_ZONE,
  })
}

/**
 * @param {*} mix
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {Boolean} options.zeroInvalid
 */
export function dateSystemZone(
  mix,
  { format = 'L', invalid = '-', append = '', zeroInvalid = true } = {}
) {
  return datetimeSystemZone(mix, { format, invalid, append, zeroInvalid })
}

/**
 * @param {*} mix
 * @param {String} options.format
 * @param {String} options.invalid
 * @param {String} options.append
 * @param {Boolean} options.zeroInvalid
 */
export function timeSystemZone(
  mix,
  {
    format = 'LT',
    invalid = '-',
    append = TIME_ZONE_APPEND,
    zeroInvalid = true,
  }
) {
  return datetimeSystemZone(mix, { format, invalid, append, zeroInvalid })
}

/**
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.format See http://numeraljs.com/#format
 * @param {String} options.invalid
 * @param {Function} options.roundMethod e.g Math.round
 */
export function number(
  mix,
  { format = '0,0.00', invalid = '-', roundMethod = null } = {}
) {
  const res = transfer.toNumeral(mix, { invalid: null })

  return res.value() === null ? invalid : res.format(format, roundMethod)
}

/**
 *  This method is rounding. 1.225 => 1.23
 *
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.format See http://numeraljs.com/#format
 * @param {String} options.invalid
 * @returns {String}
 */
export function currency(mix, { format = '$0,0.00', invalid = '-' } = {}) {
  const formattedCurrency = number(Math.abs(mix), { format, invalid })
  if (mix || mix === 0) {
    return mix >= 0 ? formattedCurrency : `-${formattedCurrency}`
  } else {
    return '-'
  }
}

/**
 * This method is not rounding. 1.225 => 1.22
 *
 * @see currency
 */
export function availableCredit(
  mix,
  { format = '$0,0.00', invalid = '-' } = {}
) {
  return number(mix, { format, invalid, roundMethod: Math.floor })
}

/**
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.format See http://numeraljs.com/#format
 * @param {String} options.append
 * @param {String} options.invalid
 */
export function rate(
  mix,
  { format = '0.00000', append = '%', invalid = '-' } = {}
) {
  const res = number(mix, { format, invalid })

  if (res === invalid) {
    return res
  }

  return `${res}${append}`
}

/**
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.format See http://numeraljs.com/#format
 * @param {String} options.append
 * @param {String} options.invalid
 * @param {Number} options.magnification
 */
export function percentage(
  mix,
  { format = '0.00', append = '%', invalid = '-', magnification = 1 } = {}
) {
  const res = transfer.toNumeral(mix, { invalid: null })
  let value = res.value()

  if (value === null) {
    return invalid
  }

  value = res.multiply(magnification).format(format)
  return `${value}${append}`
}

/**
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.invalid
 */
export function zipcode(mix, { invalid = '-' } = {}) {
  if (is.isEmpty(mix)) {
    return invalid
  }

  mix = String(mix).replace(/[^\d]/g, '')

  if (mix.length < 5) {
    return invalid
  }

  if (mix.length === 5) {
    return mix
  }

  mix = mix.padEnd(9, '0')
  return mix.substring(0, 5) + '-' + mix.substring(mix.length - 4)
}

/**
 * Mask string. e.g (123456789 => *****6789)
 *
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.invalid
 * @param {String} options.placeholder
 * @param {Number} options.length Reserved string. positive number, padding end (123456789 => 1234*****);
 *                                negative number, padding start (123456789 => *****6789).
 */
export function mask(
  mix,
  { invalid = '-', placeholder = '*', length = -4 } = {}
) {
  if (!_.isString(mix) && !_.isNumber(mix)) {
    return invalid
  }

  mix = String(mix)
  const l = mix.length
  if (l <= Math.abs(length) || length === 0) {
    return mix
  }

  if (length > 0) {
    mix = mix.substring(0, length).padEnd(l, placeholder)
  } else {
    mix = mix.substring(l + length).padStart(l, placeholder)
  }

  return mix
}

/**
 * This has some different from _.startCase(). __FOO_BAR__ => 'Foo Bar'
 *
 * @see _.startCase()
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.invalid
 */
export function startCase(mix, { invalid = '-' } = {}) {
  mix = text(mix, { invalid })
  if (mix === invalid) {
    return invalid
  }

  if (/[-_]/.test(mix)) {
    mix = mix.toLowerCase()
  }

  return _.startCase(String(mix))
}

/**
 * @param {*} mix
 * @param {Object} options
 * @param {String} options.invalid
 */
export function capitalize(mix, { invalid = '-' } = {}) {
  if (!_.isString(mix) || is.isEmptyString(mix)) {
    return invalid
  }

  return _.capitalize(mix)
}

/**
 * Formats a number as a currency string using Intl.NumberFormat.
 *
 * @param {Object} options - Options for formatting the currency.
 * @param {number} options.amount - The numeric amount to format.
 * @param {string} options.currency - The ISO 4217 currency code (e.g., 'USD', 'EUR').
 * @param {string} [options.locale='en-US'] - The locale string influencing the format (e.g., 'en-US', 'de-DE').
 * @param {Intl.NumberFormatOptions} [options.formatOptions] - Additional options for customizing the format.
 * @returns {string} - The formatted currency string.
 * eg: new Intl.NumberFormat('dem-US', { style: 'currency', currency: 'USD' }).format(
    number,
  ),
 */
export function formatIntlCurrency(options) {
  const {
    amount,
    currency,
    locale = getSystemLocale(), // Default locale if not provided.
    formatOptions = {}, // Default format options if not provided.
  } = options
  // Default currency display options merged with any custom formatOptions provided.
  const defaultFormatOptions = {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol', // Default currency display as a symbol.
    ...formatOptions, // Custom format options.
  }

  // Create a new Intl.NumberFormat instance with the locale and merged options.
  const formatter = new Intl.NumberFormat(locale, defaultFormatOptions)

  // Format the amount as a currency string and return the result.
  return formatter.format(amount)
}
