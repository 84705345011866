<template>
  <div>
    <div
      ref="textContent"
      :class="`content banner-notification__content ${
        isExpanded ? '' : 'ellipsis'
      }`"
      :style="{
        maxHeight: isExpanded ? 'none' : maxHeight + 'px',
      }"
      v-html="$utils.sanitizeHtml(content)"
    ></div>
    <div class="d-flex justify-content-end">
      <s-button
        v-if="isOverflown"
        @click.native="isExpanded = !isExpanded"
        button-type="text"
      >
        {{ isExpanded ? $ts('view_less') : $ts('view_more') }}
      </s-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
      maxHeight: 0,
      isOverflown: false,
    }
  },
  mounted() {
    this.calculateMaxHeight()
    window.addEventListener('resize', this.calculateMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateMaxHeight)
  },
  methods: {
    calculateMaxHeight() {
      this.maxHeight =
        parseFloat(getComputedStyle(this.$refs.textContent).lineHeight) * 3
      this.isOverflown = this.$refs.textContent.scrollHeight > this.maxHeight
    },
  },
}
</script>

<style scoped>
.content {
  overflow: hidden;
}
.ellipsis {
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
