<template>
  <div class="content">
    <img :src="imageUrl" alt="Not Found" />
    <h1 class="theme-text-500 mx-24">Not Found</h1>
    <p class="theme-text-500 m-0">
      {{ $t('page_not_found_description') }}
    </p>
    <s-button class="mt-24" @click.native="backHome">
      {{ $t('page_not_found_back_home') }}
    </s-button>
  </div>
</template>

<script>
import imageUrl from '../../assets/images/404.svg'

export default {
  name: 'NotFound',
  data() {
    return {
      imageUrl:
        this.$store.state.frontendConfig?.settings?.cdnUrl +
          '/static-resources/' +
          this.$store.state.frontendConfig?.settings?.staticResources?.[
            '404.svg'
          ] ?? imageUrl,
    }
  },
  methods: {
    backHome() {
      this.$route.path !== '/' && this.$router.push('/')
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  text-align: center;
  padding-top: 30vh;
}
</style>
