import { get } from 'lodash'

const RequestIdStore = new Map()

export class StoreInterceptor {
  static requestInterceptor(request) {
    setupReqRequestId(request)
    return request
  }

  static responseInterceptor(response) {
    setupRespRequestId(response)
    return response
  }
}

function setupReqRequestId(request) {
  const requestLoadId = get(request, 'requestLoadId')
  if (!requestLoadId) return

  const requestId = RequestIdStore.get(requestLoadId)
  RequestIdStore.delete(requestLoadId)
  if (!requestId) return
  request.data = { ...request.data, requestId }
}

function setupRespRequestId(response) {
  const requestStoreId = get(response, 'config.requestStoreId')
  if (!requestStoreId) return
  const traceStr = get(response, ['headers', 'x-sn-trace-id'])
  const requestId = /Root=(.*);/.exec(traceStr)?.[1]
  if (!requestId) return
  RequestIdStore.set(requestStoreId, requestId)
}
