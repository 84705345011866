const localStorage = window.localStorage

/**
 * Get local storage by keyName
 * @param {String} keName A string containing the name of the key you want to get.
 * @param {Any} [defaultValue] Set a default value when value is null
 * @return {Any}
 */
export function getLocalStorageItem(keyName, defaultValue = null) {
  const result = localStorage.getItem(keyName)
  if (result === null) {
    return defaultValue
  }

  try {
    return JSON.parse(result)
  } catch (e) {
    return defaultValue
  }
}

/**
 * Set local storage by keyName
 * @param {String} keName A string containing the name of the key you want to set.
 * @return {Undefined} None
 */
export function setLocalStorageItem(keyName, keyValue) {
  localStorage.setItem(keyName, JSON.stringify(keyValue))
}

/**
 * Remove local storage by keyName
 * @param {String} keName A string containing the name of the key you want to remove.
 * @return {Undefined} None
 */
export function removeLocalStorageItem(keyName) {
  localStorage.removeItem(keyName)
}

/**
 * Remove local storage by keyName array
 * @param {String[]} keNames A string array containing the name of the key you want to remove.
 * @return {Undefined} None
 */
export function removeLocalStorageItems(keyNames) {
  keyNames.forEach(keyName => {
    localStorage.removeItem(keyName)
  })
}

/**
 * Clear local storage
 * @param {String[]} exceptKeyNames A string array containing the name of the key you want to reserve.
 * @returns {Undefined} None
 */
export function clearLocalStorage(exceptKeyNames) {
  if (!exceptKeyNames) {
    localStorage.clear()
    return
  }
  for (let i = 0; i < localStorage.length; i++) {
    const keyName = localStorage.key(i)
    if (!exceptKeyNames.includes(keyName)) {
      localStorage.removeItem(keyName)
    }
  }
}
