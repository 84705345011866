import Axios from 'axios'

const axios = Axios.create()
const isDevelopment = process.env.NODE_ENV === 'development'
axios.interceptors.response.use(undefined, (error) => {
  const status = error?.response?.status || null
  if (status === 401) {
    window.location.href = '/auth/login'
    throw new Error('Need login.')
  } else {
    if (error instanceof Error) {
      throw error
    } else {
      console.error(error)
      throw new Error('Stop')
    }
  }
})

export function getConfiguration() {
  const devDomain =
    process.env.VUE_APP_PRODUCT_URL && process.env.VUE_APP_PRODUCT_URL.slice(8)
  const domain = isDevelopment ? devDomain : window.location.hostname
  return axios
    .get(`/v5/configuration/website/frontend-config?domain=${domain}`)
    .then(({ data, headers }) => {
      if (isDevelopment && data) {
        data.jwt = headers['x-sn-jwt-token'] || ''
      }
      return data
    })
}

export function getLangMessage(url) {
  return axios.get(url).then(({ data }) => {
    return data
  })
}

export function getAppConfig() {
  return axios.get('/app/config').then(({ data }) => {
    return data
  })
}

export function getMe() {
  return axios.get('/v5/users/me').then(({ data }) => {
    return data
  })
}

export function getIsLogin() {
  return axios.get('/auth/islogin').then(({ data }) => {
    return data
  })
}

export function loadScript(src) {
  return new Promise(function (resolve, reject) {
    if (document.querySelector('script[src="' + src + '"]')) {
      resolve()

      return
    }

    const el = document.createElement('script')

    el.type = 'text/javascript'
    el.async = true
    el.src = src

    el.addEventListener('load', resolve)
    el.addEventListener('error', reject)
    el.addEventListener('abort', reject)

    document.head.appendChild(el)
  })
}

export function goTerms(router, store) {
  const { userInfo = {} } = store.state
  // If the user needs to agree to the terms
  const termsAgree = userInfo.profile?.isAgreedToTerms ?? true
  // https://betterdebt.atlassian.net/browse/CORE-10854 proposal email view relayState not need link to terms
  const queryParams = new URLSearchParams(window.location.search)
  const relayState = queryParams.get('relayState')
  if (
    !termsAgree &&
    userInfo?.roleConfiguration?.needAgreeTerm &&
    !relayState
  ) {
    let fullPath = location.pathname + location.search
    fullPath = fullPath === '/' ? '' : fullPath
    router.push(`/login-terms?redirect=${encodeURIComponent(fullPath)}`)
    return true
  }

  return false
}

export function colorRgb(sColor) {
  sColor = sColor.toLowerCase()
  const reg = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    const sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
    }
    return 'RGB(' + sColorChange.join(',') + ')'
  }
  return sColor
}

export function rgbToHsl(color = '') {
  const colorArr = color.substring(4, color.length - 1).split(',')
  const r = (colorArr[0] /= 255)
  const g = (colorArr[1] /= 255)
  const b = (colorArr[2] /= 255)
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h = null
  let s = null
  const l = (max + min) / 2

  if (max === min) {
    h = s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return [h, s, l]
}

export function hslToRgb(h, s, l) {
  let r, g, b
  if (+s === 0) {
    r = g = b = l // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = _hue2rgb(p, q, h + 1 / 3)
    g = _hue2rgb(p, q, h)
    b = _hue2rgb(p, q, h - 1 / 3)
  }
  return `rgb(${Math.round(r * 255)},${Math.round(g * 255)},${Math.round(
    b * 255
  )})`
}

function _hue2rgb(p, q, t) {
  if (t < 0) t += 1
  if (t > 1) t -= 1
  if (t < 1 / 6) return p + (q - p) * 6 * t
  if (t < 1 / 2) return q
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
  return p
}
