import { http } from './http'
const API_VERSION = 'v5'
const LINE_URL = `/${API_VERSION}/loan-package`

export const serviceAPI = {
  editLoanException(locId, exception) {
    return http.patch(
      `${LINE_URL}/${locId}/holding-credit-rules/${exception.creditExceptionId}`,
      exception
    )
  },

  addLoanException(locId, params) {
    return http.post(`${LINE_URL}/${locId}/holding-credit-rules`, params)
  },
}
