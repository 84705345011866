import { cloneDeep } from 'lodash'

function isInvalidString(str) {
  return str === null || str === undefined || str === ''
}

function isValidString(str) {
  return typeof str === 'string' && !!str
}

export default (response) => {
  const { config, data } = response
  const { translateWarningMessage, showWarningMessage } = config || {}

  if (!(showWarningMessage || translateWarningMessage)) {
    return response
  }

  const messageModels = cloneDeep(data?.messages || data?.warnMessageList || [])

  if (!messageModels.length) {
    if (data?.warnMessage) {
      messageModels.push(cloneDeep(data.warnMessage))
    } else if (isValidString(data?.message) || isValidString(data?.code)) {
      messageModels.push({
        message: data.message,
        code: data.code,
        params: data.params,
      })
    }
  }

  if (translateWarningMessage) {
    messageModels.forEach((messageModel) => {
      const { message, code, params } = messageModel
      if (isInvalidString(message) && isValidString(code)) {
        messageModel.message = globalThis.microAppData.vue.prototype.$ts(
          code,
          params
        )
      }
    })
  }

  data.messages = messageModels.map(({ message }) => message)

  if (showWarningMessage && data.messages.length) {
    const message = data.messages.join('; ')
    globalThis.microAppData.vue.prototype.$s_confirm({
      type: 'warning',
      title: message,
      confirmText: globalThis.microAppData.vue.prototype.$ts('confirm'),
      cancelText: '',
    })
  }

  return response
}
