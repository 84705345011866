import is from 'lodash'

export function createIsAuthed(_store) {
  return function isAuthed(
    _permissionCode,
    { _isCheckRole = true, _channel } = {}
  ) {
    return true
  }
}

export function createHasAuthed(store) {
  return function hasAuthed(
    permissionCode,
    permissionValue = 3,
    pageCode = ''
  ) {
    // find permission value in action permission array if page code not null
    const actionPermission =
      store.state.userInfo?.roleConfiguration?.actionPermission || {}
    const permission = pageCode
      ? actionPermission[pageCode]
      : store.state?.codePermissions

    // If not configured the permission.
    if (!permission || !is.has(permission, permissionCode)) {
      return Number(permissionValue) === 1
    }
    return is.get(permission, permissionCode) === permissionValue
  }
}

export function createTs(router, store, i18n) {
  return function ts(message, params, { channel } = {}) {
    // const name = router.currentRoute.path.split('/')[1]
    const messages = [message]

    if (channel) {
      messages.unshift(channel + '.' + message)
    }
    // let microAppMessages = []
    // if (store.getters.microAppBaseUrls.includes(name)) {
    //   microAppMessages = messages.map((item) => name + '.' + item)
    // }

    let result = message
    // if (microAppMessages.length > 0) {
    //   for (const v of microAppMessages) {
    //     result = i18n.t(v, params)
    //     if (result !== v) {
    //       return result
    //     }
    //   }
    // }

    for (const v of messages) {
      result = i18n.t(v, params)
      if (result !== v) {
        return result
      }
    }

    console.assert(!message || message !== result, 'i18n not found:' + message)

    return result
  }
}
