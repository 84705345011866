<template>
  <transition
    enter-active-class="animate-in-top"
    leave-active-class="animate-out-top"
  >
    <div class="global-search-modal">
      <div class="global-search-mask" @click="handleClose"></div>
      <div class="global-search-container">
        <search-input
          ref="globalSearchInput"
          v-model.trim="globalSearchKey"
          @focus="handleFocus"
          @search="handleSearch"
        />
        <search-history
          v-if="showHistory || $utils.isEmpty(globalSearchKey)"
          @selectHistory="selectHistory"
        ></search-history>
        <search-result
          v-else
          :resultGroups="resultGroups"
          :globalSearchKey="globalSearchKey"
          :loading="loading"
        ></search-result>
      </div>
    </div>
  </transition>
</template>

<script>
import SearchInput from './search-input.vue'
import SearchHistory from './search-history.vue'
import SearchResult from './search-result.vue'
import _ from 'lodash'
import qs from 'qs'
import { ModulePathsEnum, SearchResponseEnum } from './global-search.enum'
import searchServiceMixin from './search-service.mixin'
import globalSearchMixin from './global-search.mixin'
import searchOriginationMixin from './search-origination.mixin'
import cmSearchGroups from './search-cm.mixin'
import { globalSearchAPI } from './api/global-search'
export default {
  name: 'global-search-modal',
  mixins: [
    searchServiceMixin,
    globalSearchMixin,
    searchOriginationMixin,
    cmSearchGroups,
  ],
  components: {
    SearchInput,
    SearchHistory,
    SearchResult,
  },
  data() {
    return {
      loading: false,
      showHistory: false,
      asyncCount: 0,
      globalSearchKey: '',
      resultGroups: [],
    }
  },
  computed: {
    searchConfig() {
      const moduleAPIArr = [
        {
          modulePath: ModulePathsEnum.service,
          groups: this.serviceSearchGroups,
        },
        {
          modulePath: ModulePathsEnum.origination,
          groups: this.originationSearchGroups,
        },
        {
          modulePath: ModulePathsEnum.cm,
          groups: this.cmSearchGroups,
        },
      ]

      return (
        moduleAPIArr.find((item) =>
          this.modulePathsIncludeCurrentPage(item.modulePath)
        ) || {}
      )
    },
    /**
     * @hideconstructor searchGroup
     * @property {Array} searchGroup
     * @property {String} groupName
     * @property {String} typeCode: GroupTypeEnum
     * @property {String} requiredMenu
     * @property {Function} normalizeResult
     * @property {Function} getResultContents
     * @property {String} modulePaths: ModuleRouterEnum
     * @property {Boolean} collapse
     *
     * @property {Array} results
     * @property {String} results.resultTitle
     * @property {Array} results.assets
     * @property {Boolean} results.showMoreAssets
     * @property {Boolean} results.showMoreContent
     * @property {String} results.resultRouter
     *
     * @property {Array} results.resultContents
     * @property {Boolean} results.resultContents.contentText
     * @property {String} results.resultContents.contentRouter
     */
    searchGroups() {
      return (
        this.searchConfig.groups?.filter((searchType) =>
          this.modulePathsIncludeCurrentPage(searchType.modulePaths)
        ) || []
      )
    },
    searchTypes() {
      const menuList =
        this.$store.state?.userInfo?.roleConfiguration?.menuList ?? []
      return this.searchGroups
        .filter((searchType) =>
          menuList.some(
            (menu) =>
              searchType.requiredMenu === menu.path ||
              (searchType.requiredMenuCode &&
                searchType.requiredMenuCode === menu.menuCode)
          )
        )
        .map((item) => item.typeCode)
    },
  },
  watch: {
    '$route.fullPath'() {
      this.handleClose()
    },
    globalSearchKey() {
      this.handleSearch()
    },
    '$store.state.IS_SHOW_GLOBAL_SEARCH'(val) {
      if (val) {
        this.$refs.globalSearchInput.$refs.searchInput.$el.focus()
      }
    },
  },
  methods: {
    handleClose() {
      this.globalSearchKey = ''
      this.resultGroups = []
      this.$store.commit('setShowGlobalSearch', false)
    },

    handleSearch: _.debounce(function () {
      if (this.$utils.isEmpty(this.globalSearchKey)) {
        return
      }

      this.showHistory = false
      this.loading = true
      this.resultGroups = []

      let params = {
        searchTerm: this.globalSearchKey, // searchTerms
        businessObjectTypes: this.searchTypes, //  businessObjectTypes
      }
      params = qs.stringify(params, { indices: false })

      const asyncCount = ++this.asyncCount
      this.$store.commit('addSearchHistory', this.globalSearchKey)

      globalSearchAPI
        .getGlobalSearch(params)
        .then((res) => {
          if (!this.$utils.isEmpty(res) && asyncCount === this.asyncCount) {
            this.setGlobalSearchResult(res)
          }
        })
        .finally(() => {
          this.loading = false
        })
    }, 1000),

    setGlobalSearchResult(results = []) {
      const searchList =
        this.searchGroups.map((searchType) => {
          const key = SearchResponseEnum[searchType.typeCode]
          const typeResults = results[key] || []

          const res = typeResults.map((data) => {
            return searchType.normalizeResult(
              data,
              searchType.getResultContents,
              searchType.typeCode
            )
          })
          this.$set(searchType, 'results', res)
          this.$set(searchType, 'collapse', false)
          return searchType
        }) || []

      this.resultGroups =
        searchList?.filter(
          (group) => group.results && group.results.length > 0
        ) || []
    },

    handleFocus() {
      this.showHistory = this.$utils.isEmpty(this.resultGroups)
    },

    selectHistory(history) {
      this.globalSearchKey = history
    },
  },
}
</script>

<style lang="scss" scope>
.global-search-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  .global-search-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #333;
    padding-top: 250px;
    opacity: 0.3;
  }
  .global-search-container {
    position: fixed;
    top: 20vh;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    opacity: 1;
    z-index: 1002;
  }
  @media (max-width: 1200px) {
    .global-search-container {
      width: 350px;
    }
  }
  @media (max-width: 750px) {
    .global-search-container {
      width: 300px;
    }
  }
  @media (max-width: 576px) {
    .global-search-container {
      width: 200px;
    }
  }
  .global-search-result-group {
    border-bottom: 1px solid $font-100;
  }
  .global-search-result-group:last-child {
    border-bottom: none;
  }
  .global-search-result-container {
    margin-top: 10px;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    max-height: 50vh;
    overflow-y: auto;
  }
  .global-search-result-item {
    height: 22px;
    line-height: 22px;
    overflow: hidden;
    &.pointer {
      cursor: pointer;
    }
    &.disable {
      cursor: not-allowed;
    }
    .name {
      flex-shrink: 0;
    }
    &.recent {
      &:hover {
        background: #f6f9fb;
      }
    }
  }
}

.animate-in-top {
  animation: InTop 0.3s;
}

.animate-out-top {
  animation: InTop 0.3s reverse;
}

@keyframes InTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
</style>
